.order-history-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .order-history-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  }
  
  .order-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 15px;
    background: #f8f9fa;
  }
  
  .order-item img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .order-item-details {
    flex-grow: 1;
  }
  
  .status-tag {
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 0.85rem;
    font-weight: 500;
  }
  
  .status-pending {
    color: #007bff;
    border: 1px solid #007bff;
  }
  
  .status-success {
    color: #28a745;
    border: 1px solid #28a745;
  }
  
  .status-failed {
    color: #dc3545;
    border: 1px solid #dc3545;
  }
  /* Định dạng cho trạng thái bảo hành */
.status-warranty-pending {
  color: #ff9800;
  border: 1px solid #ff9800;
}

.status-warranty-success {
  color: #4caf50;
  border: 1px solid #4caf50;
}

.status-warranty-failed {
  color: #f44336;
  border: 1px solid #f44336;
}
.warranty-button {
  padding: 4px 12px;
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: 500;
  color: #1976d2; /* Màu Blue của MUI primary */
  border: 1px solid #1976d2;
  background: transparent;
  margin-top: 8px; /* Khoảng cách với trạng thái */
  display: inline-block;
  transition: all 0.3s ease;
  min-width: 120px; /* Đảm bảo kích thước tối thiểu trên mobile */
  text-align: center;
}

.warranty-button:hover {
  background: #1976d2;
  color: #fff;
  border-color: #1976d2;
  cursor: pointer;
}